import RouteErrorElement from "@app/layouts/ErrorLayout/RouteErrorElement";
import { fetchContext } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";

import AboutsUs from "./AboutUs/AboutUs";
import VideoLandingPage from "./VideoLandingPage";

const errorElement = import.meta.env.PROD ? <RouteErrorElement /> : undefined;

/**
 * Routes specific to HBS
 */
export const pubRoutes: RouteObject[] = [
  {
    path: "/videos",
    element: <VideoLandingPage />,
    errorElement,
    loader: fetchContext,
  },
  {
    path: "/about-us",
    element: <AboutsUs />,
    errorElement,
    loader: fetchContext,
  },
];
